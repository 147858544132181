.console-text {
    font-family: 'Source Code Pro', monospace !important;
    font-size: 20px;
    color: var(--secondary);
}

.console-warning {
    color: var(--warning);
    white-space: pre-wrap;
}
.console-warning-x {
    color: var(--gray);
}
.console-warning-x:hover {
    color: var(--warning);
}


#console-input {
    background-color: #00000000;
    border: none;
}
#console-input:focus {
    outline: none;
}