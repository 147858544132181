// Bootstrap Customization
:root {
    --primary: #116abe;
    --secondary: #176608;
    --warning: #e74856;
    --light: #e1e1e1;
    --gray: #606060;
    --dark: #333333;
  }


$theme-colors: (
    "primary": #116abe,
    "secondary": #d04d11,
    "tertiary": #555555,
    "console": #176608,
    "success": #96b38a,
    "warning": #ffc107,
    "danger": #dc3545,
    "info": #17a2b8,
    "light": #999999,
    "dark": #333333
);

@import "bootstrap";

// font notes
// Sacramento - I'm Paul.
// Comfortaa - section titles / navbar links?
// Oxanium - Student | Software Developer
// Source Code Pro - Console

// React Components SCSS
#Home {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #121212;
    h3,
    h4 {
        font-family: 'Comfortaa', cursive;
    }
}

#Console {
    z-index: 2;
    position: fixed;
    bottom: 0;
    padding: .4em .4em 0 .4em;
    background-color: rgba(0, 0, 0, 0.6);
}

#Navbar {
    background-color: rgba(255, 255, 255, 0.025);
    max-width: 20%;
    font-size: 1.5vw;

    nav {
        padding: 1em;

        #profile-card {
            h3 {
                z-index: 3;
                margin: 0 -.5em;
                font-family: 'Comfortaa', cursive;
                font-size: 2.5vw;
                color: #ffffff;
                transform: rotate(-90deg);
            }

            #wrapper {
                z-index: 4;

                img {
                    margin-left: -50px;
                    margin-right: 50px;
                    width: 110px;
                    height: 110px;
                    border-radius: 15px;
                }
            }
        }

        h6 {
            font-family: 'Oxanium', cursive;
            font-size: 1vw;
            color: #555555;
        }

    }
}

#Contact {
    background-color: rgba(255, 255, 255, 0.0125);
    position: fixed;
    top: 0;
    right: 0;

    a {
        padding: 1em;
    }
}

#Welcome {
    h1 {
        font-family: 'Sacramento', cursive;
        font-size: 7.5vw;
    }
}

#About {
    margin-bottom: 2em;

    #hobbies {
        min-height: 100vh;
    }

    #awards {
        min-height: 100vh;
    }
}

#Certifications {
    a {
        text-decoration: none;
        color: inherit;
    }

    a:hover {
        color: #116abe;
    }
}

// Bootstrap Classes
.container {
    padding-right: 2.5em;
}

// Custom Classes
.cursor-blink {
    animation: blink 1000ms infinite;
}

.section {
    margin-right: 48px;
    min-height: 100vh;
}
