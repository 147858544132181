/* Profile */
.slide-in {
    animation: slide-in 1000ms ease-out forwards;
}
.slide-out {
    animation: slide-out 1000ms ease-out forwards;
}

@keyframes slide-in {
    from {
        transform: translateX(50%);
    }
    to {
        transform: translateX(0%);
    }
}

@keyframes slide-out {
    from {
        transform: translateX(0%);
    }
    to {
        transform: translateX(50%);
    }
}

/* NavLinks */
a {
    font-family: 'Comfortaa', cursive;
    color: #999999 !important;
}

a:hover {
    color: #116abe !important;
    transform: scale(110%);
}

.active {
    color: #116abe !important;
}