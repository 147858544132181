.project-container {
    background-color: #fff;
    height: fit-content;
    width: 300px;
    transition: transform .3s ease-in-out;
}

.project-container:hover {
    transform: scale(1.1);
}

span {
    color: #C84210;
    transition: color 0.3s ease-in-out;
}

a {
    text-decoration: none !important;
}
