h1 {
    cursor: default;
    color: #fff;
}

h1:hover {
    animation: flicker 3000ms infinite;
}

.start {
    animation: flicker-off 2750ms forwards;
}

@keyframes flicker-off {

    55.001%, 56%, 57.001%, 78%, 80.001% {
        text-shadow:
            0 0 6px rgba(202, 228, 225, 0.92),
            0 0 30px rgba(202, 228, 225, 0.34),
            0 0 var(--secondary),
            0 0 21px var(--secondary),
            0 0 34px var(--secondary),
            0 0 54px var(--secondary);
    }

    56.001%, 57%, 78.001%, 80%, 100% {
        text-shadow: none;
    }
}

@keyframes flicker {

    0%, 19.999%, 22%, 62.999%, 64%, 64.999%, 70%, 100% {
        text-shadow:
            0 0 6px rgba(202, 228, 225, 0.92),
            0 0 30px rgba(202, 228, 225, 0.34),
            0 0 12px var(--secondary),
            0 0 21px var(--secondary),
            0 0 34px var(--secondary),
            0 0 54px var(--secondary);
    }

    20%, 21.999%, 63%, 63.999%, 65%, 69.999% {
        text-shadow: none;
    }
}