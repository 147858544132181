body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

/* GLOBAL CSS */
h3 {
    color: var(--primary);
}
h4 {
    color: var(--secondary);
}
h5 {
    color: white;
}
h6 {
    color: var(--light);
}

p {
    color: var(--light);
}

li {
    color: var(--light);
}